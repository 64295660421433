.mentor-messages-page {
  height: 100%;
  display: flex;
  padding: 10px 100px;
  flex-direction: column;
  gap: 20px;
}

.ant-select {
  border-radius: 32px;
}

.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 32px;
}

.ant-input-group >.ant-input:first-child {
  border-start-start-radius: 32px;
  border-end-start-radius: 32px;
}

.ant-input-group .ant-input-group-addon:last-child {
  border-start-end-radius: 32px;
  border-end-end-radius: 32px;
}

.ant-modal-body {
  div {
    .ant-typography {
      margin-bottom: 0;
      margin-top: 2px;
    }
  }
}

@media (max-width: 680px) {
  .mentor-messages-page {
    height: auto;
    padding: 10px 10px;

    .ant-table-wrapper {
      overflow: auto;
    }
  }
}
