.videos-page {
  height: 100%;
}

.empty-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 680px) {
  .videos-page {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
}

