.learn-page {
  height: 100%;
  display: flex;
  padding: 10px 100px 0 100px;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .footer {
    border: 1px solid #f0f0f0;
    border-radius: 8px 8px 0 0;
    padding: 20px;
    width: 100%;
    background-color: #f9f9f9;
  }
}

.empty-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 680px) {
  .learn-page {
    height: auto;
    padding: 10px 10px;
  }
}
