.not-found-page {
  height: 100%;
  display: flex;
  padding: 10px 100px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media (max-width: 680px) {
  .not-found-page {
    padding: 10px 10px;
  }
}
