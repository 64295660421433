.reset-password-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 100px;

  .content {
    height: 100%;
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 15px;

    .ant-divider-horizontal {
      margin-top: 0;
      margin-bottom: 20px;
    }

    .ant-input-group >.ant-input:first-child {
      border-start-start-radius: 32px;
      border-end-start-radius: 32px;
    }

    .ant-input-group .ant-input-group-addon:last-child {
      border-start-end-radius: 32px;
      border-end-end-radius: 32px;
    }
  }
}

@media (max-height: 950px) {
  .reset-password-page {
    height: auto;
  }
}

@media (max-width: 680px) {
  .reset-password-page {
    height: auto;
    padding: 10px 10px;
  }
}
